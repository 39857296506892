import React from "react";
import {Nav} from "react-bootstrap";
import { GeoAltFill, QuestionCircle, MapFill } from "react-bootstrap-icons"
import { Link } from "@reach/router";





const Sidebar = () => {
 

   
    return (
        <>
  
            <Nav className="col-lg-12 d-none d-md-block bg-light sidebar" >
            <p className="sidebarTitle">Análisis por:</p>
                <div className="sidebar-sticky"></div>
            <Nav.Item>
                <Link  to="/app/dashboard"><MapFill /> Provincias</Link>
            </Nav.Item>
            <Nav.Item>
                <Link to="/app/poblacion"><GeoAltFill /> Poblaciones</Link>
            </Nav.Item>
            <Nav.Item >
                <Nav.Link  eventKey="disabled" disabled>
                Documentación
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
            <Link to="/app/indices">< QuestionCircle/> Índices</Link>
            </Nav.Item>
            <Nav.Item>
            <Link to="/app/informes">< QuestionCircle/> Informes</Link>
            </Nav.Item>
            </Nav>
      
        </>
        );
  }
 
  export default Sidebar