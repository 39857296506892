
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import Sidebar from '../Dashboard/sidebar'
import { Link } from "@reach/router"



const Indices = () => {
    
    
    
    
        return (
            
            <Container fluid >
                <Row>
                
                        <Col xs={2} id ="sidebar-wraper">
                            <Sidebar />
                        </Col>
                        <Col xl={10}>
                        <div className="p-3">
                        <h1>Índices</h1>
                        <p>Los índices son listado de residencias o datos ordenados bajo un criterio de valor:  </p>
                        </div>
                        <Row>
                        <Col md={5}>
                            <Card className="mb-2">
                                <Card.Body>
                                    
                                   <StaticImage src="../../images/logo-full90.png" className="p-3"/>
                                    
                                
                                    <Card.Title className="mt-3">Índice de transparencia</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">
                                        Residencias en España
                                    </Card.Subtitle>
                                    <Card.Text>
                                    El Índice de Transparencia de inforesidencias (IT) se creó con el animo de promover la transparencia de información por parte de las residencias gariatricas en España. 
                                    </Card.Text> 
                                    <Button variant="primary" target="_blank" href="http://inforesidencias.com/contenidos/mayores-y-familia/nacional/que-es-el-indicador-de-transparencia-de-las-residencias#content">Más información</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={5}>
                        <Card className="mb-5" >
                               
                            <Card.Body>
                                
                                <StaticImage src="../../images/indiceir2.png" width="600px"/>
                                
                                
                             
                                    <Card.Title className="mt-3">Índice IR</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">
                                        El "Big Mac idex" de las residencias 
                                    </Card.Subtitle>
                                    <Card.Text>
                                    Inspirado en famoso Big Mac Index del Economist, este índice mide el precio de las residencias de España en relación con el poder adquisitivo, comparandolo ademas por países. 
                                    </Card.Text> 
                                    <Link to="/app/indices/ir">
                                        <Button variant="primary">Más información</Button>
                                    </Link>    
                            </Card.Body>
                        </Card>
                        </Col>
                        </Row>
                        </Col>
                        
                        
                </Row>
            </Container>
            
        )
   
}

export default Indices   