import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import Sidebar from '../Dashboard/sidebar'
import { FilePdf } from 'react-bootstrap-icons'
import preciosCCAA from "../../static/precios-CCAA.pdf"
import contextoAfi from "../../static/contexto-afi.pdf"





const Informes = () => {
    
    
    
    
        return (
            
            <Container fluid >
                <Row>
                
                        <Col xs={2} id ="sidebar-wraper">
                            <Sidebar />
                        </Col>
                        <Col xl={10}>
                        <div className="p-3">
                        <h1>Informes</h1>
                        <p>Son documentos que analizan o describen un conjunto de datos</p>
                        </div>
                        <Row>
                        <Col md={5}>
                            <Card className="mb-2">
                                <Card.Body>
                                    
                                   <StaticImage src="../../images/portada-contexto.png" />
                                    
                                
                                    <Card.Title className="mt-3">La dependencia</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">
                                        A través de los datos
                                    </Card.Subtitle>
                                    <Card.Text>
                                    Contexto del uso de datos e inteligencia de datos en el análisis de la dependenicia de las personas mayores. 
                                    </Card.Text> 
                                    <Button href={contextoAfi} target="_blank"><FilePdf /> Ver informe</Button>
                                    
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={5}>
                        <Card className="mb-5" >
                               
                            <Card.Body>
                                
                                <StaticImage src="../../images/precios.png" className="p-3"/>
                                
                                
                             
                                    <Card.Title className="mt-3">Informes de precios</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">
                                        Residencias de España
                                    </Card.Subtitle>
                                    <Card.Text>
                                    IX Informe de Inforesidencias sobre precios  de residencias en España con información a nivel general, por provincias y comunidades autonomas.
                                    </Card.Text> 
                                    <Button href= {preciosCCAA} target="_blank"><FilePdf /> Precios por CCAA</Button>{'   '}
                                        <Button variant="outline-secondary" target="_blank" href="https://www.inforesidencias.com/contenidos/noticias/nacional/el-precio-medio-de-una-residencia-geriatrica-privada-en-espa-a-es-de-1830-al-mes#content" target="_blank" >Más información</Button>
                                        
                            </Card.Body>
                        </Card>
                        </Col>
                        </Row>
                        </Col>
                        
                        
                </Row>
            </Container>
            
        )
   
}

export default Informes   