import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Sidebar from "./sidebar"

const Poblacion = () =>{

    return(
    <>
    
  <Container fluid>
    <Row>
      <Col xs={2} id ="sidebar-wraper">
      <Sidebar />
      </Col>
      <Col xs={10}>
      <iframe title="Residencias transparentes geolocalizadas"
                src="https://afianalytics.shinyapps.io/app1_infores/" width="1000" height="800" />    
      </Col>      
    </Row>        
  </Container>           
  </>
  )
        
}
    
  
  export default Poblacion