import React , { useContext }from "react"
import { navigate } from "gatsby"
import { AuthContext } from "../context/auth"




const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { user } = useContext(AuthContext)
  if (!user && location.pathname !== `/app/acceso`) {
    navigate("/app/acceso")
    return null
  
  
  
  
  /*if (!isLoggedIn() && location.pathname !== `/app/login`) {
    navigate("/app/acceso")
    const alerta = console.log()
    return alert(alerta)
   */ 
  }
  return <Component {...rest} 
  
  />
}


export default PrivateRoute