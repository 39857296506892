import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../../components/PrivateRoute"
import Dashboard from "../../components/Dashboard"
import Login from "../../components/acceso"
import Registros from "../../components/registros"
import Layout from "../../components/Layaout"
import RestEmail from "../../components/RestEmail"
import Poblacion from "../../components/Dashboard/poblacion"
import Indices from "../../components/Indices"
import Ir from "../../components/Indices/Ir"
import Informes from "../../components/Informes"


const App = () => {
    return (
  <Layout>
    <Router basepath="/app">
    <Login path="/acceso"/>
    <RestEmail path="/resetemail"/>
    <Registros path="/registro"/>
    <PrivateRoute path="/dashboard" component={Dashboard}  />
    <PrivateRoute  path ="/poblacion" component={Poblacion} />
    <PrivateRoute  path ="/indices" component={Indices} />
    <PrivateRoute  path ="/indices/ir" component={Ir} />
    <PrivateRoute  path ="/informes" component={Informes} />
    </Router>
  </Layout>
  )
}
export default App