import React from 'react'
import { Container, Row, Col, Nav } from 'react-bootstrap'
import Sidebar from '../Dashboard/sidebar'
import { Link } from 'gatsby'


const Ir = () => {




return (

<Container fluid>
   <Row>

      <Col xs={2} id="sidebar-wraper">
      <Sidebar />
      <Nav className="col-lg-12 d-none d-md-block sticky-top sidebar" >
         <p className="sidebarTitle text-muted">Tabla de contenido</p>
             <div className="sidebar-sticky"></div>
         <Nav.Item>
             <Link  to="#contexto"> Introducción</Link>
         </Nav.Item>
         <Nav.Item>
             <Link to="#punto de partida"> Punto de partida</Link>
         </Nav.Item>
         <Nav.Item>
             <Link to="#conclusiones"> Conclusiones</Link>
         </Nav.Item>
         <Nav.Item>
             <Link to="#fuentes"> Fuentes</Link>
         </Nav.Item>
         <Nav.Item >
             <Nav.Link  eventKey="disabled" disabled>
             Tablas y graficos
             </Nav.Link>
         </Nav.Item>
         <Nav.Item>
         <Link to="#precios1"> Precios residencias</Link>
         </Nav.Item>
         <Nav.Item>
         <Link to="#salario1">S.mínimo mensual</Link>
         </Nav.Item>
         <Nav.Item>
         <Link to="#precios-salarios">Precios y S.mínimos</Link>
         </Nav.Item>
         <Nav.Item>
         <Link to="#precios-sm">Relación precios S.mínimo</Link>
         </Nav.Item>
         <Nav.Item>
         <Link to="#precios-sme">Relación precios S.medio</Link>
         </Nav.Item>
         <Nav.Item>
         <Link to="#sm-sme">S.minímo / S.medio</Link>
         </Nav.Item>
         <Nav.Item>
         <Link to="#precio2">Precio real vs realativo</Link>
         </Nav.Item>
         
         
         </Nav>
      </Col>
      <Col xl={10}>
      
         <h1 className="mt-3">Índice ir</h1>
         <p>Por: <a href="https://www.linkedin.com/in/josep-de-mart%C3%AD-vall%C3%A9s-84062836/?originalSubdomain=es">Josep de Martì</a>,  Septiembre 2021</p>
         <h2 className="text-muted">Comparación de precios entre residencias de mayores en varios países europeos</h2>
         <Row>
         <Col md={5} className="mt-3">
            <iframe width="342" height="517" seamless frameborder="0" scrolling="no" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQSbSXFN_PehMrXfookpowtw678CSBXcUmlV3ncRCecI44hbU8xqaJ7KTwOsQkKzA/pubchart?oid=1336167915&amp;format=interactive"></iframe>
            </Col>
            <Col md={5} className="mt-3">
               <ul>
                  <li>
                     Los precios de las residencias en España, entre los más bajos de Europa, tanto en términos absolutos como
                     relativos.
                  </li>
                  <li>
                     Las residencias más caras de Europa en términos absolutos están en Noruega, Suiza y Suecia.
                  </li>
                  <li>
                     Las residencias más caras en términos relativos en Holanda, Noruega y Suecia.
                  </li>
               </ul>
            </Col>
            
         </Row>
         <h4 className="mt-3" id="contexto">Introducción</h4>
            <p>Aunque el fenómeno del progresivo envejecimiento de la población es común en todos los países de nuestro
               entorno, la forma en que se afronta la dependencia que le acompaña y la prestación de servicios,
               especialmente
               los sustitutorios del hogar, es muy variada, no sólo si se hacen comparaciones internacionales sino, incluso
               dentro
               de zonas geográficas de un mismo país.
            </p>
            <p>La Covid-19 y su efecto letal en personas mayores que vivían en residencias, sobre todo en los primeros
               meses de la pandemia,
               está haciendo que se plantee en casi todo el mundo la necesidad de hacer evolucionar los modelos actuales de
               residencias para
               lo que se buscan aquellos que han podido ser más resilientes o, por algún motivo han mostrado mejores
               resultados para intentar
               utilizarlos como modelos de buenas prácticas.

            </p>
            <p>
               El problema al hacer comparaciones es que existe siempre un factor económico a tener en cuenta que también
               es heterogéneo.
               En cada país las residencias tienen un coste y un precio diferente por lo que intentar trasladar soluciones
               intentadas de un país
               a otro puede resultar fuera de lugar si no se tiene en cuenta la diferencia real de costes económicos.

            </p>
            <p>
               Resultaría por eso interesante contar con una herramienta que permitiese comparar de forma coherente los
               precios de residencias
               en diferentes países. Para hacerlo se podrían tener en cuenta normativas arquitectónicas, ratios de personal
               y condiciones
               salariales; o podría plantearse la pregunta “¿cuánto esfuerzo económico debe asumir alguien que vaya a pagar
               por el precio de una
               residencia?”.
            </p>

            <p>
               Inforesidencias.com, portal líder en el sector de las búsqueda y comparación de residencias para mayores en
               España, que lleva quince
               años publicando periódicamente datos sobre precios de las residencias privadas en España, ha decidido crear
               el “Indicador IR” para
               comparar los precios de las residencias de tercera edad en diferentes países de una forma racional que
               permita determinar dónde son
               verdaderamente más caras en términos, no solo absolutos sino también relativos.
            </p>

         <h4 className="mt-3" id="punto de partida">Punto de partida</h4>


            <p>En muchos países europeos en los que existe un sistema de atención sanitaria universal, cuando se calcula el
               precio de estancia en una residencia de mayores se suman dos o tres elementos (el coste de los servicios
               hoteleros, el de los servicios asistenciales y los sanitarios). Como el coste de la atención sanitaria la
               suele asumir de forma directa o indirecta el sistema de salud pública correspondiente, puede considerarse
               que el precio de una residencia sería aquel que incluya los servicios de acogimiento, manutención, atención
               en las Actividades de la Vida Diaria y un cierto apoyo a los sistemas públicos de salud. Tomando datos de
               diferentes fuentes obtenemos el siguiente cuadro</p>

               <iframe id="precios1" width="530" height="283" seamless frameborder="0" scrolling="no" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQSbSXFN_PehMrXfookpowtw678CSBXcUmlV3ncRCecI44hbU8xqaJ7KTwOsQkKzA/pubchart?oid=618480161&amp;format=interactive"></iframe>

            <p>La primera conclusión al ver la distribución es que existe una gran variedad de precios.</p>

            <p>En principio por un precio que oscila entre los 8.940€ al mes en Noruega y los 1.350€ en Chequia una persona
               está obteniendo un servicio hotelero completo, atención en las actividades de la vida diaria 24h/365d y un
               cierto apoyo sanitario.</p>

            <p>Los países podrían agruparse, desde esta perspectiva en tres grupos: precios entre 6.000 y 9.000€ a mes;
               entre 2.700 y 4.000€ y entre 1.300€ y 2.000€.</p>

            <p>¿Quiere eso decir que las residencias son “caras” en Noruega y “baratas” en Chequia? En España, con un
               precio de 1.830€/mes ¿son caras o baratas las residencias?</p>

            <p>El concepto “caro/barato” tiene que ver tanto con el precio del bien o servicio como con la capacidad
               económica de quien considere la compra. No es lo mismo pagar 3.000€ al mes por vivir en una residencia si se
               ingresan 1.800 que 6.000. Por eso, algunas explicaciones intuitivas a la gran diferencia de precios en los
               diferentes países podrían fundamentarse en que los salarios de cada país también son muy dispares o en que
               lo que ofrecen las residencias en los diferentes países también lo son.</p>

            <p>En este contexto, desde Inforesidencias.com hemos planteado la creación de un índice al que hemos denominado
               “IR” que permite comparar el esfuerzo que puede suponer a alguien que ingrese el salario mínimo el pagar los
               servicios de una residencia. No entramos a la hora de crear el índice en quién en cada país va a acabar
               pagando efectivamente la residencia (en algunos casos el precio será asumido íntegramente por el usuario
               mientras en la mayoría la administración asumirá parte o todo a través de programas públicos de atención a
               la dependencia).</p>

            <p>Partimos de la base de que, aunque los servicios específicos que se ofrece en una residencia en Noruega y en
               Chequia sean muy diversos, la necesidad que vienen a cubrir ambos son la misma: ofrecer un servicio
               residencial, de apoyo en las Actividades de la Vida Diaria para atender a personas mayores en situación de
               dependencia que no pueden recibirlo en sus domicilios.</p>

            <p>Como punto de partida hemos obtenido datos de salarios mínimos de los diferentes países (en España el
               Salario Mínimo Interprofesional es de 1.108 Euros al mes, mensualizando las 14 pagas al año) y, para
               aquéllos que no cuentan con la figura jurídica del Salario Mínimo legalmente establecido (como Austria,
               Italia o Suecia) hemos tomado datos de salarios de gerocultoras/auxiliares de residencias, que en la mayoría
               de los países suelen ser cercanos a ese salario mínimo o uno que pueda ser similar. En relación con este
               dato hay que tener en cuenta que cada país legisla introduciendo peculiaridades por lo que hemos intentado
               obtener datos homogéneos al extraer las cifras de fuentes que mostrasen datos de números elevados de países.
            </p>
            <iframe id="salario1" width="530" height="283" seamless frameborder="0" scrolling="no" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQSbSXFN_PehMrXfookpowtw678CSBXcUmlV3ncRCecI44hbU8xqaJ7KTwOsQkKzA/pubchart?oid=587139953&amp;format=interactive"></iframe>
            <p>La primera observación al ver el gráfico es que la distribución y el orden de los países no varía demasiado.
               Los cuatro países con salarios mínimos más bajos son los cuatro que tienen las residencias más baratas; los
               tres con salarios más altos son los que tienen las más caras.</p>

               <iframe id="precios-salarios" width="530" height="335" seamless frameborder="0" scrolling="no" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQSbSXFN_PehMrXfookpowtw678CSBXcUmlV3ncRCecI44hbU8xqaJ7KTwOsQkKzA/pubchart?oid=1267091723&amp;format=interactive"></iframe>   
            <p>En Inforesidencias.com hemos planteado obtener un primer índice dividiendo el precio mensual de las
               residencias de cada país entre el Salario Mínimo mensual.

            </p>
            <p>El resultado hace variar la distribución de forma interesante
            </p>
            <iframe id="precios-sm" width="530" height="283" seamless frameborder="0" scrolling="no" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQSbSXFN_PehMrXfookpowtw678CSBXcUmlV3ncRCecI44hbU8xqaJ7KTwOsQkKzA/pubchart?oid=64916306&amp;format=interactive"></iframe>
            <p>Las residencias son las cuartas más caras de la muestra en términos absolutos, en cambio si consideramos el
               precio en relación con el salario de los que menos ganan se convierten en las más caras de Europa. Se
               necesita 3,7 el salario mínimo Holandés para pagar el precio de una residencia holandesa. Suiza es un caso
               muy interesante, con precios de residencia de 7.200€ al mes podría ser considerada como un país con
               residencias caras, en cambio si consideramos que allí el salario mínimo es de casi 3.500€ pasa a situarse en
               el medio de la tabla con un índice de 2,1, en una posición cercana a la de España , que se sitúa en el 1,7
               queda en la parte media baja. Eso quiere decir que el precio de una residencia en España equivale a 1,7
               veces el Salario Mínimo Interprofesional.
            </p>
            <p>Esta primera aproximación disminuye la diferencia de precios entre países en relación a una comparación
               absoluta que tenga en cuenta los precios en Euros. Así, resulta que el país en que las residencias son más
               caras (Noruega, 8.940€/mes) su precio es 6,6 veces más alto que el del país donde son más baratas (Chequia,
               1.350€/mes). En cambio, si aplicamos la proporción, el más alto (Holanda, 3,7) es “solo” tres veces más
               elevado que el más bajo (vuelve a ser Chequia, 1,2).
            </p>
            <p>Aunque este indicador da un resultado más comparable, la realidad es que la proporción de trabajadores que
               ingresa el Salario Mínimo suele ser bajo y varía mucho de un país a otro.
            </p>
            <p>Para salvar ese obstáculo hemos tomado otro dato estadístico que es el Salario Medio y lo hemos vuelto a
               poner en relación con el precio mensual de la residencia, obteniendo el índice IR2
            </p>

            <iframe id="precios-sme" width="530" height="300" seamless frameborder="0" scrolling="no" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQSbSXFN_PehMrXfookpowtw678CSBXcUmlV3ncRCecI44hbU8xqaJ7KTwOsQkKzA/pubchart?oid=1843904467&amp;format=interactive"></iframe>
            <p>España, con un salario medio de 2.244 Euros al mes es de los pocos países en el que el precio medio de una
               residencia está por debajo del salario medio por lo que el esfuerzo que supone pagar una plaza en una
               residencia es inferior al de otros países. Así, España sería de los países en el que el esfuerzo para pagar
               una residencia es objetivamente inferior.
            </p>
            <p>La parte alta de la tabla no varía, se ve así que Noruega, Suecia y Holanda siguen siendo las residencias
               con precios relativos más altos ya se utilice el Salario Mínimo o el Medio como indicador.

            </p>
            <p>La parte baja en cambio sí que es diferente con el indicador de Salario Medio, resulta llamativo que cinco
               de los países comparados (Austria, Eslovenia, Bélgica, España y Alemania) tienen un precio de la plaza en
               residencia inferior al salario medio. Especialmente sorprendente el caso de Alemania que, utilizando este
               indicador, tendría las residencias proporcionalmente más baratas de Europa en relación con los salarios
               medios.</p>

               <iframe id="sm-sme" width="530" height="280" seamless frameborder="0" scrolling="no" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQSbSXFN_PehMrXfookpowtw678CSBXcUmlV3ncRCecI44hbU8xqaJ7KTwOsQkKzA/pubchart?oid=1344192961&amp;format=interactive"></iframe>
            <p>Con la finalidad de obtener datos que sean fácilmente comparables, en Inforesidencias.com proponemos
               realizar una media entre Salario Mínimo y Salario Medio de cada país estudiado. De esta forma obtenemos un
               salario que al estar entre el mínimo y el medio corresponderá a la banda media baja de la pirámide salarial,
               a esta cifra la hemos denominado “Salario Mínimo-Medio” SMM
            </p>
            <p>Al dividir el precio de las residencias entre SMM obtenemos el “Índice IR” que grosso modo viene a decirnos
               cómo es el precio de una residencia en comparación con los ingresos de una persona con índices de ingresos
               medios-bajos.
            </p>
            <p>Alemania, con un IR de 1,01 sirve como referencia. Allí el SMM y el precio medio de una residencia casi
               coinciden, Noruega, con 2,29 marca el índice IR más elevado.
            </p>
            <p>España se sitúa con un IR de 1,09 se sitúa en la parte baja.
            </p>
            <p>Los países escandinavos, especialmente Noruega, son los que cuentan con residencias con un Indide IR más
               elevado.
            </p>
            <p>Usando el índice podemos hacer comparaciones reales de lo que cuestan las resiencias en diferentes países
               desde la perspectiva de alguien con ingresos medios/bajos.
            </p>
            <p>El indicador también permite plantearse una pregunta: Si pienso con la mentalidad de un asalariado de nivel
               medio/bajo español y me pongo en el lugar de alguien con mi situación económica de otro país ¿Qué costaría
               desde mi punto de vista la residencia? El índice IR permite saber el precio relativo desde nuestra
               perspectiva.
            </p>
            <p>En este caso precio relativo se obtiene dividiendo el precio real del país a comparar por el IR del país a
               comparar y multiplicándolo por el IR español (que és de 1,09).
            </p>
            <p>En el caso de Suiza 7.200/1,4*1,09= 5.605,71€</p>
            <p>Así podemos decir que el precio de 7.200’€ al mes de una residencia Suiza, trasladado a España se
               convertiría en unos 5.600€. Una cantidad que sigue siendo muy elevada pero que nos permite hacernos una idea
               de cómo la puede ver un suizo.</p>
                  
               <iframe id="precios2" width="530" height="360" seamless frameborder="0" scrolling="no" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQSbSXFN_PehMrXfookpowtw678CSBXcUmlV3ncRCecI44hbU8xqaJ7KTwOsQkKzA/pubchart?oid=1271447617&amp;format=interactive"></iframe>

         <h4 className="mt-3" id="conclusiones">Conclusiones</h4>
            <p>El Indice IR permite comparar el precio de las residencias en relación con los ingresos de las familias de
               clase media de los diferentes países.
            </p>
            <p>Un índice 1 indica que el precio mensual de una residencia corresponde con el ingreso de una persona que
               gana una cantidad algo superior al Salario Mínimo Interprofesional.
            </p>
            <p>Tres países de la muestra: Noruega, Suecia y Holanda destacan por tener unos precios sustancialmente más
               elevados que el resto, cercanos o superiores a 2. Coinciden además con ser países en los que el precio
               absoluto también es el más elevado por lo que se pone de manifiesto la importancia que se da tanto desde el
               sector público como el privado al sector.
            </p>
            <p>Las plazas de residencias privadas en España tienen un precio bajo si se compara con otros países de nuestro
               entorno. Este precio bajo, en comparación, se mantiene también si se considera no sólo en términos absolutos
               sino también en relación con la capacidad adquisitiva de los ciudadanos del país (tanto si se toma como
               unidad de comparación el Salario Mínimo, el Salario Medio o el SMM).

            </p>
         <h4 className="mt-3" id="fuentes">Fuentes</h4>
            <ol>
               <li><a href="https://www.statista.com/study/89601/residential-care-in-europe/">Residential care in
                     Europe</a>,
               </li>
               <li>
                  <a
                     href="https://www.orpea-corp.com/images/orpeafinance/pdf/Documentation/FR/2021/ORPEA_DEU_FR_2020_ea58c.pdf">
                     Orpea.Document d'enregistrement universel. 2020</a>
               </li>
               <li><a href="https://www.elderguru.com/portugal-elder-care-and-assisted-living-options/">
                     Portugal Elder Careand Assisted Living Options</a></li>
               <li><a href="https://checkinprice.com/average-minimum-salary-in-vienna-austria/">
                     Average and Minimum Salary in Vienna, Austria</a></li>
               <li><a href="https://www.salaryexpert.com/salary/job/nursing-assistant/italy">
                     Salary Expert Italy</a></li>
               <li>
                  <a
                     href="https://www.erieri.com/salary/job/nurses-assistant/sweden#:~:text=Salary%20Recap,education%20for%20a%20Nurses%20Assistant">
                     ERI's Nurses Assistant Salary in Sweden</a>
               </li>
               <li><a href="https://datosmacro.expansion.com/smi">
                     Expansión: SMI - Salario Mínimo Interprofesional</a></li>
               <li><a href="https://docs.google.com/document/d/11JOgsfA6WNfUCtK0QOzADtgHNxWj0WTu/edit">
                     Datos Holanda</a></li>
               <li><a href="https://ec.europa.eu/eurostat/statistics-explained/index.php?title=File:Proportion_of_employees_earning_less_than_105_%25_of_the_monthly_minimum_wage,_October_2010_and_2014_(%25)_YB17_I.png#filelinks">
                     Proportion of employees earning less than 105 % of the monthly minimum wage, October 2010 and 2014 (%) YB17</a></li>
               <li><a href="https://datosmacro.expansion.com/mercado-laboral/salario-medio">Expansión: Salario medio</a> </li>  
               </ol>
         </Col>


   </Row>
</Container>

)

}

export default Ir