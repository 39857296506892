import React, { useState, useContext } from "react"
import { navigate } from '@reach/router'
import firebase from "gatsby-plugin-firebase"
import { Form, Button, Alert, Container } from "react-bootstrap"



const RestEmail = () => {

  

  const [data, setData] = useState({
    email: "",
    error: null,
  })


  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setData({ ...data, error: null })
    const email = data.email;
  // [START auth_send_password_reset]
  firebase.auth().sendPasswordResetEmail(email)
    .then(() => {
      alert("Le hemos enviado un email, consulte su bandeja de entrada");
      navigate("/app/acceso")
    })
    .catch((error) => {
      var errorMessage = error.message;
      setData({ ...data, error: errorMessage })
    });}

  return (
    <>
    <body style={{backgroundColor: "rgb(238,238,238)", minHeight: "100vh"}}>
<Container style={{ maxWidth: `600px`, display: "fex", justifyContent: "center", alignItems: "center", padding: "3rem"}}>
      <h4>Recordación de contraseña</h4>
      <p>Por favor indique el email con el que se dió de alta y le enviaremos un enlace para restablecer su contraseña</p>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
        
          <Form.Label>Email</Form.Label>
          <br />
          <Form.Control
            type="text"
            name="email"
            value={data.email}
            onChange={handleChange}
          />
         </Form.Group> 
        
        {data.error ? <Alert variant="danger">{data.error}</Alert> : null}
        <Button cassName="mt-3" as ="input" type="submit" value="Enviar email para restablecer contraseña" />
        
      </Form>
</Container>  
</body>    
    </>
  )
}

export default RestEmail
