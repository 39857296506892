import React, { useState, useContext } from "react"
import { navigate, Link } from '@reach/router'
import firebase from "gatsby-plugin-firebase"
import { AuthContext } from "../context/auth"
import { Form, Alert, Button, Container} from "react-bootstrap"




const Login = () => {

  

  const [data, setData] = useState({
    email: "",
    password: "",
    error: null,
  })

  const { setUser } = useContext(AuthContext)

  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setData({ ...data, error: null })
    try {
      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(data.email, data.password)
      setUser(result);
      navigate('/app/dashboard');
      
    } catch (err) {
      setData({ ...data, error: err.message })
    }
  }

  return (
    <>
    <body style={{backgroundColor: "rgb(238,238,238)", minHeight: "100vh"}}>
    <Container style={{ maxWidth: `400px`, display: "fex", justifyContent: "center", alignItems: "center", padding: "3rem"}} >
      
    <h4>Credenciales de acceso</h4>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formAcceso">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={data.email}
            onChange={handleChange}
          />
         </Form.Group> 
        <Form.Group className="mb-3" controlId="formContraseña">  
        <Form.Label>Contraseña</Form.Label>
        <Form.Control
            type="password"
            name="password"
            value={data.password}
            onChange={handleChange}
          />
          <Form.Text> 
          <Link to="/app/resetemail">¿Olvidó su contraseña?</Link>
        </Form.Text> 
        </Form.Group>  
         
        {data.error ? <Alert variant="danger">{data.error}</Alert> : null}
        <Button as="input" type="submit" value="Acceder" />
      </Form>
     
    </Container>
    </body> 
      
    </>
  )
}

export default Login
