import React, { useState, useContext } from "react"
import SEO from "./seo"
import firebase from "gatsby-plugin-firebase"
import { AuthContext } from "../context/auth"
import { Link, navigate } from "gatsby"
import { Container, Row, Col, Form, Alert, Button, Card } from "react-bootstrap"






const Registros = () => {

    const timestamp = Date.now()
    const f = new Intl.DateTimeFormat('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timestamp)
    const [data, setData] = useState({
        fecha: f,
        email: "",
        password: "",
        nombre: "",
        apellido: "",
        empresa: "",
        cargo: "",
        tipo: "cliente",
        boletin: false,
        condiciones: false,

        error: null,
    })


    const { setUser } = useContext(AuthContext)

    const handleChange = e => {
        setData({ ...data, [e.target.name]: e.target.value },
            initialState => ({ condiciones: !initialState.condiciones }),
            initialState => ({ boletin: !initialState.boletin }))
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setData({ ...data, error: null })
        try {
            const result = await firebase
                .auth()
                .createUserWithEmailAndPassword(data.email, data.password)
                .then((userCredential) => {
                    userCredential.user.sendEmailVerification(result)
                })

            /*
            Código opcional para envio de confirmación por email, el problema es que trunca el registro del usuario en la BD
            Necesitariamos crear dos colecciónes una para registrados autenticados y otra para no autenticados.

            .then((userCredential)=>{
                userCredential.user.sendEmailVerification(User);
                auth().signOut();
                alert("Le hemos enviado un email de verificación que es necesario para poder comprobar su identidad");
                })
                .catch(alert);*/

            setUser(result);

            if (data.err !== null) {
                e.preventDefault()
                firebase
                    .firestore()
                    .collection("usuarios")
                    .doc(data.email)
                    .set({
                        fecha: data.fecha,
                        nombre: data.nombre,
                        apellido: data.apellido,
                        empresa: data.empresa,
                        cargo: data.cargo,
                        tipo: data.tipo,
                        boletin: data.boletin,
                        condiciones: data.condiciones,
                    })


                navigate("/gracias");


            }
        } catch (err) {
            setData({ ...data, error: err.message })
        }
    };



    return (
        <>
            <SEO title="Register" />
            <Container className="mt-3 mb-3">
                <Row >
                    <Col md={6}>
                        <div className="p-3">
                        <h4>Datos disponibles</h4>
                        <p>Al registrarse podrá acceder a los últimos informes, índices e inicadores. Actualmente estamos trabajando en los siguientes informes:</p>
                        
                     <Card className="mb-2">
                         <Card.Body>
                             <Card.Title >La dependencia en España</Card.Title>
                             <Card.Subtitle className="mb-2 text-muted">
                                A través de los datos
                             </Card.Subtitle>
                             <Card.Text>
                             Contexto del uso de datos e inteligencia de datos en el análisis de la dependeicia de las personas mayores
                            </Card.Text> 
                         </Card.Body>
                     </Card>
                     <Card className="mb-2">
                         <Card.Body>
                             <Card.Title>Precios de residencias</Card.Title>
                             <Card.Subtitle className="mb-2 text-muted">
                                Provincias y poblaciones
                             </Card.Subtitle>
                             <Card.Text>
                             Anális historico de los precios de las residencias privadas en España por provincias y poblaciones
                            </Card.Text> 
                         </Card.Body>
                     </Card>
                     <Card className="mb-2">
                         <Card.Body>
                             <Card.Title>Índice de transparencia</Card.Title>
                             <Card.Subtitle className="mb-2 text-muted">
                                Residencias en España
                             </Card.Subtitle>
                             <Card.Text>
                             Geolocalización de residencias listadas por índice de trasparencia (IT) y precio. El IT permite ver las residencias que hacen publicos información y datos que permiten comparar la gestión del centro.
                            </Card.Text> 
                         </Card.Body>
                     </Card>
                     <Card>
                     <Card.Body>
                             <Card.Title>Índice IR</Card.Title>
                             <Card.Subtitle className="mb-2 text-muted">
                                El "Big Mac idex" de las residencias 
                             </Card.Subtitle>
                             <Card.Text>
                             Inspirado en famoso Big Mac Index del Economist, este índice mide el precio de las residencias de España en relación con el poder adquisitivo, comparandolo ademas por países. 
                            </Card.Text> 
                         </Card.Body>
                     </Card>
                        </div>
                    </Col>
                    <Col md={6} shadow-sm style={{backgroundColor:"rgb(238,238,238)", borderRadius: "10px", boxShadow: "-1px 1px 25px rgb(0, 0, 0, 0.2)"}}>
                        <h4 className="p-3">Registro</h4>
                        <Form className="p-3" onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="nombre"
                                    value={data.nombre}
                                    onChange={handleChange}
                                    placeholder=""
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Apellido</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="apellido"
                                    value={data.apellido}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Organización</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="empresa"
                                    value={data.empresa}
                                    onChange={handleChange}
                                    placeholder="empresa, institución etc... "
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label >Su cargo </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="cargo"
                                    value={data.cargo}
                                    onChange={handleChange}
                                    placeholder="profesión"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label >Soy</Form.Label>
                                <Form.Select
                                    required
                                    name="tipo"
                                    value={data.tipo}
                                    onChange={handleChange}
                                >
                                    <option value="cliente">Cliente de inforesidencias</option>
                                    <option value="potencial-cliente">Represento a una residencia o grupo residencial</option>
                                    <option value="consultor-gero">Consultor en el sector geroasitencial</option>
                                    <option value="consultor-fin">Consultor en el sector financiero</option>
                                    <option value="compra-venta">Compra-venta de residencias</option>
                                    <option value="prensa">Prensa o medio de comunicación</option>
                                    <option value="investigador">Investigador de master o PHD</option>
                                    <option value="a-publica">Administración pública</option>
                                    <option value="otro">Otro</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label >Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="email"
                                    value={data.email}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Contraseña</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    value={data.password}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    required
                                    type="checkbox"
                                    name="condiciones"
                                    checked={data.condiciones}
                                    onChange={handleChange}
                                    label= "Estoy de acuerdo con las condiciones y la politica de seguridad."
                                />
                                <Form.Text><Link to="/condiciones">Ver condiciones</Link></Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    name="boletin"
                                    checked={data.boletin}
                                    onChange={handleChange}
                                    label="Quiero inscribirme al boletín de inforesidencias con novedades del sector geroasistencial"
                                />
                            </Form.Group >

                            {data.error ? <Alert variant={"danger"}>{data.error}</Alert> : null}
                            <Button as="input" type="submit" value="Solicitar acceso" />
                        </Form>

                    </Col>

                </Row>
            </Container>
        </>
    )

}
export default Registros